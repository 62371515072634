
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { exportUserRegRemain, exportUserRegRemainUserList, getUserRegRemain, getUserRegRemainUserList } from "@/api/request/bigdata";

//组件
@Component({
  name: "Retention",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private checked: boolean = false;
  private listLoading: boolean = true;

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //请求数据
  private listQuery: any = {
    //临时数据
    time: [],

    //请求数据
    type: 0,
    internal: 0,
    end_time: "",
    start_time: "",

    //是否导出
    excel: false,
  };

  //用户类型
  private typeOptions: any[] = [
    { value: 0, label: "全部" },
    { value: 1, label: "全部用户" },
    { value: 2, label: "博主" },
    { value: 3, label: "男用户" },
    { value: 4, label: "女用户" },
  ];

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }

    //获取数据
    const { data } = await getUserRegRemain(this.listQuery);

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()} 23:59:59`;
    this.listQuery.start_time = `${DTCls.getCurDataTimeYMD30()} 00:00:00`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
  }

  //处理重置
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //初始化数据
    this.checked = false;
    this.listQuery.type = 0;
    this.listQuery.internal = 0;

    //获取数据
    this.getList();
  }

  //处理导出
  private async handleExcel() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;

    //获取数据
    const { data } = await exportUserRegRemain(this.listQuery);

    //保存数据
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}留存`) : saveAs(data, "留存总览");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //获取数据
    this.getList();
  }

  //修改内部
  private checkChange(val: any): void {
    //数据赋值
    this.listQuery.internal = val ? 1 : 0;

    //获取数据
    this.getList();
  }

  //获取留存详情
  private getKeepDetail(row: any, type: any): void {
    //数据赋值
    this.keepInfo = {};
    this.keepList = [];
    this.userChecked = false;
    this.keepParams.internal = 0;
    this.keepParams.date = row.date;
    this.keepParams.keep_type = type;

    //获取数据
    this.getKeepDetailList();

    //显示对话框
    this.keepDialogVisible = true;
  }

  //-------------------------------- 留存详情 --------------------------------
  //定义变量
  private keepInfo: any = {};
  private keepList: any[] = [];
  private keepTotal: number = 0;
  private userChecked: boolean = false;
  private keepDialogVisible: boolean = false;
  private keepDialogLoading: boolean = false;

  //请求参数
  private keepParams: any = {
    //页码数据
    page: 1,
    row: 20,

    //请求数据
    type: 0,
    date: "",
    internal: 0,
    keep_type: 0,

    //是否导出
    excel: false,
  };

  //关闭留存详情
  private keepClose(): void {
    //隐藏对话框
    this.keepDialogVisible = false;
  }

  //导出列表
  private async keepExcel() {
    //显示等待
    this.keepDialogLoading = true;

    //数据赋值
    this.keepParams.excel = true;

    //导出数据
    const { data } = await exportUserRegRemainUserList(this.keepParams);

    //保存数据
    saveAs(data, `${this.keepParams.date}`);

    //隐藏等待
    setTimeout(() => {
      this.keepDialogLoading = false;
    }, 0.5 * 1000);
  }

  //获取留存详情
  private async getKeepDetailList() {
    //显示等待
    this.keepDialogLoading = true;

    //获取数据
    const res = await getUserRegRemainUserList(this.keepParams);

    //数据赋值
    this.keepList = res.data.users;
    if (!this.keepParams.excel) {
      this.keepInfo = { ...res.data };
      this.keepTotal = res.data.total;
    }

    //隐藏等待
    setTimeout(() => {
      this.keepDialogLoading = false;
    }, 0.5 * 1000);
  }

  //性别筛选
  private sexFormatter(row: any): string {
    //判断返回
    if (row.sex === 0) return "保密";
    if (row.sex === 1) return "男";
    if (row.sex === 2) return "女";

    //返回空
    return "";
  }

  //是否内部改变
  private userCheckChange(val: any): void {
    //数据赋值i
    this.keepParams.internal = val ? 1 : 0;

    //获取留存详情
    this.getKeepDetailList();
  }

  //处理修改页码
  private handleCurrentChange(val: any): void {
    //数据赋值
    this.keepParams.page = val;

    //获取数据
    this.getKeepDetailList();
  }
}
